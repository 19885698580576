<template>
  <Section :options="computedOptions.sectionOptions" class="apartments">
    <div class="row gutter-40 col-mb-80">

      <!-- Apartments Content
        ============================================= -->
        <div :class="' col-lg-12'">

          <div v-if="apartments && apartments.length > 0" id="shop" class="shop">

            <ApartmentList v-for="(apartment, index) in apartments" :key="'apartment'+index+'_'+apartment._id" :options="getApartmentOptions(apartment)" :labels="getApartmentLabels(apartment)"></ApartmentList>

          </div>
          <div v-else>

            {{ getLocalizedText(labels.noApartmentToDisplay) }}

          </div> 
        </div><!-- .postcontent end -->
    </div>

  </Section><!-- #content end -->

</template>

<style>

</style>


<script lang="ts">
import Section, {SectionOptions} from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { CmsContent, CmsEnum, CmsLabel, CmsPicture, CmsText, CmsFile, CmsNumber } from '@fwk-client/modules/cms/types';
import { defineComponent, PropType, computed, Ref } from '@fwk-node-modules/vue'
import { languagesTypes } from '@fwk-client/store/types';
import { getApp, useCmsModule, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import ApartmentList, { ApartmentListLabels, ApartmentListOptions } from './ApartmentList.vue'

/** @cmsOptions */
export interface ApartmentsOptions {
    /** @cmsType CmsInterface */
    sectionOptions?:SectionOptions
    /** @cmsType CmsText */
    agencyCode?:CmsText;
    /** @cmsType CmsText */
    apartmentPageName?:CmsText
}

/** @cmsLabels */
export interface ApartmentsLabels {
    /** @cmsType CmsLabel */
    noApartmentToDisplay?:CmsLabel
}

/** @cmsSlots */
export interface ApartmentsSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<ApartmentsOptions>,
        labels: {
          type: Object as PropType<ApartmentsLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<ApartmentsSlots>
    },
    components : {
        Section,
        ApartmentList
    },
    setup(props, context) {

        const app = getApp();
        const $router = useRouter();
        const $store = useStore();
        const cms = useCmsModule();

        const computedOptions:ApartmentsOptions = {
            sectionOptions : {
                section:false
            },
            apartmentPageName : 'hospitality-apartment',
            ...props.options,
        };

        const computedLabels:ApartmentsLabels = {
          noApartmentToDisplay: app.$t('hospitality.apartment.no-apartment-to-display'),
          ...props.labels
        }

        const apartments:Ref<any[]> = computed(() => {
          return [...app.$hospitality_apartments];
        });

        const getApartmentOptions = (apartment:any):ApartmentListOptions => {

          var picture = apartment.thumbnails && apartment.thumbnails.length > 0 ? app.getStaticURLFromPath(apartment.thumbnails[0]) : undefined;
          return {
            viewDetailsPageName:computedOptions.apartmentPageName,
            viewDetailsCode:apartment.code,
            picture:picture
          } as ApartmentListOptions
        }

        const getApartmentLabels = (apartment:any):ApartmentListLabels => {
          return {
            title:apartment.title,
            description:apartment.summary
          } as ApartmentListLabels
        }

        return {
            computedOptions: {
                ...computedOptions
            },
            apartments,
            getApartmentOptions,
            getApartmentLabels,
            labels:computedLabels
        }

    }
})
</script>